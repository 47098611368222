import request from '@/utils/request'
import requestSCM from '@/utils/scm-request'

/**
 *
 *
 * @description 下拉框接口
 * @memberOf summer1874
 * @date 2019-10-31
 */
// 查询导出
export function ExcelTaskPage(params) {
  return requestSCM({
    url: `/scm-purchase/export/page`,
    method: 'get',
    params
  })
}
export function downloadUrl(params) {
  return requestSCM({
    url: '/scm-purchase/export/download-url',
    method: 'get',
    params
  })
}
// 公司列表
export function companyList() {
  return request({
    url: 'auth/company/list',
    method: 'get'
  })
}
// 公司列表
export function companyLis1() {
  return request({
    url: 'auth/company/list',
    method: 'get'
  })
}

// 国家信息查询
export function queryStateList(params) {
  return request({
    url: `/base/basestate/list`,
    method: 'get',
    params
  })
}
// 省份信息查询
export function queryProvinceList(params) {
  return request({
    url: `/base/baseprovince/list`,
    method: 'get',
    params
  })
}

// 城市信息查询
export function queryCityList(params) {
  return request({
    url: `/base/basecity/list`,
    method: 'get',
    params
  })
}
// 县区信息查询
export function queryCountyList(params) {
  return request({
    url: `/base/basecounty/list`,
    method: 'get',
    params
  })
}

// 平台信息查询
export function queryTterraceList(params) {
  return request({
    url: `/base/baseterrace/list`,
    method: 'get',
    params
  })
}
// Style信息查询
export function queryStyleList(params) {
  return request({
    url: `/base/basestyle/list`,
    method: 'get',
    params
  })
}
// 条件查询新版color
export function queryBaseColorListNew(params) {
  return request({
    url: `/base/basecolor/listNew`,
    method: 'get',
    params
  })
}
// 条件查询size
export function queryBaseSizeList(params) {
  return request({
    url: `/base/basesize/list`,
    method: 'get',
    params
  })
}
// 通过Style ID查询sku生成需要的数据
export function querySkuListByStyle(id) {
  return request({
    url: `/base/basesku/queryStyleInfoAndAttr/${id}`,
    method: 'get'
  })
}
// 品牌信息查询
export function queryBrandList() {
  return request({
    url: `/base/basebrand/list`,
    method: 'get'
  })
}

// 供应商信息查询
export function queryVendorList() {
  return request({
    url: `/base/basevendor/list`,
    method: 'get'
  })
}
// 仓库信息查询
export function queryWarehouseList(params) {
  return request({
    url: `/wms-base/basewarehouse/list`,
    method: 'get',
    params
  })
}
// 储区信息查询
export function queryStorageareaList() {
  return request({
    url: `/wms-base/basestoragearea/list`,
    method: 'get'
  })
}

// 储区信息查询
export function queryStorageareaListByParam(params) {
  return request({
    url: `/wms-base/basestoragearea/list`,
    method: 'get',
    params
  })
}
// Size信息查询
export function querySizeList(params) {
  return request({
    url: `/base/basesize/list`,
    method: 'get',
    params
  })
}
// Color信息查询
export function queryColorList(params) {
  return request({
    url: `/base/basecolor/list`,
    method: 'get',
    params
  })
}
// 类别信息查询
export function queryCategoryList(params) {
  return request({
    url: `/base/basecategory/list`,
    method: 'get',
    params
  })
}

// 站点条件查询
export function querySiteList(params) {
  return request({
    url: `/base/basesite/list`,
    method: 'get',
    params
  })
}
// 平台条件查询
export function queryTerraceList(params) {
  return request({
    url: `/base/baseterrace/list`,
    method: 'get',
    params
  })
}

// 货代信息查询
export function forwarderList() {
  return request({
    url: `/wms-base/basefreightforwarding/list`,
    method: 'get'
  })
}
// 月台信息查询
export function platList() {
  return request({
    url: `/wms-base/basegoodsplatform/list`,
    method: 'get'
  })
}
// 员工信息查询
export function baseemployeeList() {
  return request({
    url: `/auth/employee/list`,
    method: 'get'
  })
}
// 根据员工id查询租户及其仓库名称
export function wareHouseAndTenantByEmployeeId(id) {
  return request({
    url: `/wms-base/basewarehouse/wareHouseAndTenantByEmployeeId/${id}`,
    method: 'get'
  })
}
// 查询租户列表
export function tenantList() {
  return request({
    url: `/auth/tenant/list`,
    method: 'get'
  })
}

// 采购员下拉数据
export function getPurchasePersonnelList() {
  return request({
    url: `/base/basevendor/getPurchasePersonnelList`,
    method: 'get'
  })
}

export function getCargoOwner() {
  return request({
    url: `/wms-base/cargoowner/getCargoOwner`,
    method: 'get'
  })
}

export function ExcelRefresh(params) {
  return request({
    url: `/excel/config/refresh`,
    method: 'get',
    params
  })
}

export function getStyleListByStyleNames(data) {
  return request({
    url: `/base/basestyle/getStyleListByStyleNames`,
    method: 'post',
    data
  })
}

export function basebu() {
  return request({
    url: `/base/basebu`,
    method: 'get'
  })
}

export function userList(params) {
  return request({
    url: `/base/user/select`,
    method: 'get',
    params
  })
}

export function innerOrganization(params) {
  return requestSCM({
    url: `/scm-purchase/sign/select/innerOrganization`,
    method: 'get',
    params
  })
}

export function organizationSealtypes(params) {
  return requestSCM({
    url: `/scm-purchase/sign/select/organizationSealtypes`,
    method: 'get',
    params
  })
}

export function cargoByVendor(params) {
  return requestSCM({
    url: `/scm-purchase/basic/cargoVendor`,
    method: 'get',
    params
  })
}

export function warehouseList(params) {
  return request({
    url: `/base/base/getWarehouse`,
    method: 'get',
    params
  })
}

export function getWarehouse(params) {
  return requestSCM({
    url: `/scm-purchase/basic/getWarehouse`,
    method: 'get',
    params
  })
}

export function qmsUserList(params) {
  return requestSCM({
    url: `/scm-purchase/basic/qms/user`,
    method: 'get',
    params
  })
}

export function qmsDictList(params) {
  return requestSCM({
    url: `/scm-purchase/basic/qms/dict`,
    method: 'get',
    params
  })
}

export function listAllEmployeeApi(params) {
  return requestSCM({
    url: `/scm-purchase/basic/listAllEmployee`,
    method: 'get',
    params
  })
}

export function listLaunchSeasonAPi(params) {
  return requestSCM({
    url: `/scm-purchase/basic/listLaunchSeason`,
    method: 'get',
    params
  })
}

export function productCategoryApi(params) {
  return requestSCM({
    url: `/scm-purchase/basic/productCategory`,
    method: 'get',
    params
  })
}

