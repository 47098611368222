<template>
  <!-- 采购台账 -->
  <div class="app-container">
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      style="display: flex;"
    >
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="采购订单" :class="$i18n.locale">
              <el-input
                v-model="form.purchaseOrderCode"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="BU名称" :class="$i18n.locale">
              <el-input
                v-model="form.operatorGroup"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购员" :class="$i18n.locale">
              <el-input
                v-model="form.purchasePersonnel"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="业务实体" :class="$i18n.locale">
              <el-select
                v-model="form.companyName"
                value-key="id"
                clearable
                filterable
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.companyName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select
                v-model="form.vendorId"
                value-key="id"
                multiple
                collapse-tags
                clearable
                filterable
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="季节" :class="$i18n.locale">
              <el-select
                v-model="form.season"
                value-key="id"
                multiple
                collapse-tags
                clearable
                filterable
              >
                <el-option
                  v-for="item in _getAllCommodityDict('SEASON')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SKU" :class="$i18n.locale">
              <el-input
                v-model="form.sku"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="form.value"
                type="datetimerange"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select
                v-model="form.style"
                value-key="id"
                multiple
                collapse-tags
                clearable
                filterable
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="FNSKU/UPC" :class="$i18n.locale">
              <el-input
                v-model="form.fnskupc"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <el-button
              type="primary"
              @click="handleExport('data')"
            >导出</el-button>
            <el-button
              type="primary"
              @click="handleExport('style')"
            >按STYLE导出</el-button>
            <el-button
              type="primary"
              @click="handleExport('img')"
            >按图片导出</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRowClick()">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa', color: '#606266' }"
      max-height="500px"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="purchaseOrderCreateTime"
        label="PO创建日期"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        prop="buyerCompanyName"
        label="业务实体"
        width="180"
        align="center"
        sortable
      />

      <el-table-column
        prop="vendorCode"
        label="供应商编码"
        width="160"
        align="center"
        sortable
      />

      <el-table-column
        prop="vendorName"
        label="供应名称"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="platform"
        label="平台"
        width="100"
        align="center"
        sortable
      />
      <el-table-column
        prop="site"
        label="站点"
        width="100"
        align="center"
        sortable
      />
      <el-table-column
        prop="operatorGroup"
        label="BU名称"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="purchasePersonnel"
        label="采购员"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="categoryName2"
        label="样式名称"
        width="160"
        align="center"
        sortable
      />

      <el-table-column
        prop="categoryName3"
        label="种类名称"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="seasonI18"
        label="季节"
        width="100"
        align="center"
        sortable
      />
      <el-table-column
        prop="skuImage"
        align="center"
        sortable
        label="图片"
        width="160"
      >
        <template slot-scope="scope">
          <img
            :src="scope.row.skuImage"
            alt="暂无图片"
            style="width: 50px;height: 50px"
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="style"
        label="style"
        width="160"
        align="center"
        sortable
      />

      <el-table-column
        prop="sku"
        label="sku"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="shoeBatchNo"
        label="鞋印批次号"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="fnsku"
        label="FNSKU"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="upc"
        label="UPC"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="purchasePlanCode"
        label="PR编码"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="purchaseOrderCode"
        label="PO号"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="purchaseNumber"
        label="采购数量（双）"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="color"
        label="颜色"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="size"
        label="尺码（US）"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="boxQuantity"
        label="箱数"
        width="160"
        align="center"
        sortable
      />

      <el-table-column
        prop="cartonNumber"
        label="双/箱"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="currencyType"
        label="币种"
        width="160"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ getAuditTypeLabel("CURRENCY_TYPE", scope.row.currencyType) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="includingTaxPrice"
        label="含税价"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="excludingTaxPrice"
        label="不含税价"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="purchaseTotalPrice"
        label="采购总金额"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="predictDeliveryDate"
        label="要求到货日期"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="deliveryFeedback"
        label="交期反馈-备注"
        width="150"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <el-popover
            ref="popover"
            placement="right"
            trigger="click"
            @show="contentDetail(scope.row.purchaseOrderCode)"
          >
            <el-table
              :data="Vendordatas"
              max-height="250px"
              style="width: 100%"
            >
              <el-table-column
                width="80"
                property="orderNum"
                label="序号"
                align="center"
              />
              <el-table-column
                width="100"
                property="vendorDeliveryFeedback"
                label="交期反馈"
                align="center"
              />
              <el-table-column
                width="100"
                property="createByName"
                label="操作人"
                align="center"
              />
              <el-table-column
                width="150"
                property="createTime"
                label="操作时间"
                align="center"
              />
              <el-table-column
                width="150"
                property="deliveryRemark"
                label="备注"
                align="center"
              />
            </el-table>
          </el-popover>
          <div v-popover:popover>{{ scope.row.deliveryFeedback }}</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="qualityTime"
        label="质检日期"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="qualityUser"
        label="质检员"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="qualityStatus"
        label="质检结果"
        width="160"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.qualityStatusI18 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="qualityStatus"
        label="订单状态"
        width="160"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.orderStatusI18 }}
        </template>
      </el-table-column>

      <el-table-column
        prop="collectNumber"
        label="接受入库量"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="unCollectNumber"
        label="未交货数量"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="collectPrice"
        label="已收货金额"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="unCollectPrice"
        label="未收货金额"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="inWarehouseStatus"
        label="入库状态"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="collectBatchNoAndPairs"
        label="收货批次号-数量"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="warehousingCreateTime"
        label="收货时间"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="inWarehouseTime"
        label="WMS入仓日期（上架）"
        width="130"
        align="center"
        sortable
      />
      <el-table-column
        prop="boxCube"
        label="箱规"
        width="120"
        align="center"
        sortable
      />
      <el-table-column
        prop="caseCube"
        label="盒规"
        width="120"
        sortable
        align="center"
      />
      <el-table-column width="200" label="材料">
        <template v-if="scope.row.myList" slot-scope="scope">
          <div v-for="(item, index) in scope.row.myList" :key="index">
            <div v-if="item.value">{{ item.name }}：{{ item.value }}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="pagination"
      />
    </div>
  </div>
</template>
<script>
import { VendorDeliveryFeedback, purchaseAccountPage, queryVendorList, queryStyleList } from '@/api/scm-api'
import Pagination from '@/components/Pagination'
import { companyList } from '@/api/listSelection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { exportPurchaseAccount, exportPurchaseAccountStyle, purchaseAccountImage } from '@/api/export'
import { omit } from 'lodash'

export default {
  components: { Pagination },
  mixins: [commodityInfoDict],
  data() {
    return {

      editRow: {},
      companyOptions: [],
      vendorOptions: [],
      TableLoading: false,
      warehouseOption: [],
      showRow: false, // 切换显示input
      tableDatas: [],
      styleOptions: [],
      Vendordatas: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      form: {
        purchaseOrderCode: '',
        value: [],
        companyName: '',
        vendorId: [],
        style: [],
        season: ''
      }
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      const { style, vendorId, season, value = [], ...restForm } = this.form
      const [purchaseOrderStartTime = '', purchaseOrderEndTime = ''] = value
      return {
        ...this.pager,
        ...restForm,
        style: style?.join(),
        vendorId: vendorId?.join(),
        season: season?.join(),
        purchaseOrderStartTime,
        purchaseOrderEndTime
      }
    },
    exportParams() {
      return omit(this.queryParameter, Object.keys(this.pager))
    }

  },
  mounted() {
    this._purchasePage()
    this._queryStyleList()
    this._companyList()
    this._queryVendorList()
  },
  methods: {
    pagination(val) {
      Object.assign(this.pager, val)
      this._purchasePage()
    },
    async handleExport(type) {
      try {
        if ((['style', 'img'].includes(type)) && !this.form.style.length) return this.$message.warning('请先选择style')
        this.loading = true
        switch (type) {
          case 'style':
            await exportPurchaseAccount(this.exportParams)
            break
          case 'data':
            await exportPurchaseAccountStyle(this.exportParams)
            break
          case 'img':
            await purchaseAccountImage(this.exportParams)
            break
        }
        this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
      } finally {
        this.loading = false
      }
    },

    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._purchasePage()
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this.queryClick(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 获取公司信息数据
    async _companyList() {
      const { datas } = await companyList()
      this.companyOptions = datas
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 默认查询
    async _purchasePage() {
      try {
        this.TableLoading = true
        const { datas: { pager, records }} = await purchaseAccountPage(this.queryParameter)
        this.pager = pager
        this.tableDatas = records
        this.tableDatas.forEach(data => {
          const list = JSON.parse(data.skuSpecificationJson)
          data.myList = list.map(e => ({ name: e.display.name, value: e.value.value }))
        })
      } finally {
        this.TableLoading = false
      }
    },

    // 供应商交期时间记录查询
    contentDetail(orderCode) {
      this._VendorDeliveryFeedback(orderCode)
    },
    async _VendorDeliveryFeedback(params) {
      const { datas } = await VendorDeliveryFeedback(params)
      this.Vendordatas = datas
    }

  }
}
</script>
<style lang="scss" scope>
.specialColor {
  color: red;
}
.el-tooltip__popper {
  max-width: 20%;
}
.el-tooltip__popper,
.el-tooltip__popper.is-dark {
  background: #e6e6e6 !important;
  color: #303133 !important;
}
</style>
